import React, { useEffect, useState } from "react";
import blogData from "../../data/blog.json";
import "./index.css";
import BannerText from "../../components/banner/bannerText";
import Layout from "../../layout";
import { useParams } from "react-router-dom";
import { removeTurkishChars } from "../../utils/urlUtils";
import "./index.css";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";

const BlogDetails = () => {
  const { title } = useParams();
  const [blogPost, setBlogPost] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/blog`);
        if (!response.ok) {
          throw new Error("Data could not be fetched");
        }
        const data = await response.json();
        const post = data.find(
          (blog) =>
            removeTurkishChars(blog.title)
              .replace(/\s+/g, "-")
              .toLowerCase() === title
        );
        setBlogPost(post);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchBlogData();
  }, [title]);
  useEffect(() => {
    if (blogPost && blogPost.info) {
      const parsedInfo = ReactHtmlParser(blogPost.info);
      const firstParagraph = parsedInfo.find(
        (element) => element.type === "p"
      );
      const metaDescription = firstParagraph
        ? firstParagraph.props.children
        : "";

      // Meta description tag'i güncelleme işlemi
      const metaDescriptionTag = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.setAttribute("content", metaDescription);
      } else {
        const newMetaTag = document.createElement("meta");
        newMetaTag.name = "description";
        newMetaTag.content = metaDescription;
        document.head.appendChild(newMetaTag);
      }
    }
  }, [blogPost]);

  if (error) {
    return <p>{error}</p>;
  }

  if (!blogPost) {
    return <p>Blog post not found</p>;
  }

  return (
    <Layout>
      <Helmet>
        <title>{blogPost.title} | Crewier</title>
        <link
          rel="canonical"
          href={`https://www.crewier.com/blog-detay/${removeTurkishChars(
            blogPost.title
          )
            .replace(/\s+/g, "-")
            .toLowerCase()}`}
        />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="blog-details-page-main">
        <BannerText pageTitle="" />
        <div className="blog-details-page pb-5">
          <div className="container pt-5 ">
            <div className=" col-lg-12 mt-lg-5 ">
              <div className="d-flex justify-content-between align-items-end">
                <h1 className="p-0 m-0 ">{blogPost.title} </h1>
                <div className="d-flex flex-column align-items-end">
                  <span>Tarih:</span>
                  <p>29.08.2024</p>
                  <br />
                  <span>Yazar:</span>
                  <p>Crewier</p>
                </div>
              </div>
              <div
                className="parallax mt-3 d-lg-block d-md-block d-none"
                style={{
                  backgroundImage: `url(https://server.crewier.com/uploads/${blogPost.img})`,
                }}
              ></div>

              <img
                className="d-lg-none d-md-none d-block w-100 mt-4"
                src={blogPost.img}
                alt={blogPost.title}
              ></img>
              <h6 className="mt-4">{ReactHtmlParser(blogPost.info)} </h6>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BlogDetails;
